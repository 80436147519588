<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    export let direction: 'horizontal' | 'vertical' = 'horizontal';

    let divClass = 'bg-gray-200 dark:bg-gray-600 flex-none';
    let directionStyle = direction === 'horizontal' ? 'w-full h-px ' : 'w-px';
</script>

<div {...$$restProps} class={twMerge(divClass, directionStyle, $$props.class)} />

<!--
  @component
  ## Features
    단순한 구획선을 그립니다.
  ## Props
    @prop direction: 'horizontal' | 'vertical'
-->
